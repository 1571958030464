import React, {useEffect, useState} from "react";
import Layout from "../layouts";
import Seo from "../components/Seo";
import {Link} from "gatsby";
import {FormattedMessage} from "react-intl";
import {StaticImage} from "gatsby-plugin-image";

const PrivacyPolicyEn = ({location}) => {

    const [optedIn, setOptedIn] = useState(true);
    useEffect(() => {
        window._paq?.push([function () {
            if (this.isUserOptedOut()) {
                setOptedIn(false);
            } else {
                setOptedIn(true);
            }
        }])
    })

    const toggle = () => {
        console.log(optedIn);
        if (optedIn) {
            window._paq?.push(['optUserOut']);
            setOptedIn(false);
        } else {
            window._paq?.push(['forgetUserOptOut']);
            setOptedIn(true);
        }
    }

    return (
        <Layout lang="en" showNav={false}>
            <Seo lang="en" title="Privacy Policy" noIndex={true} location={location} slug="/privacy-policy"/>
            <div className="flex flex-row md:items-center">
                <div className="flex-1">
                    <Link className="cursor-pointer text-sm" to={`/en`}>{`❮ `}<FormattedMessage id="back"/> </Link>
                    <h1 className="text-2xl md:text-3xl font-bold mt-2 mb-8">Privacy Policy</h1>
                    <div className="text-lg ">
                        <p>This policy describes which data is collected when using this page.</p>
                        <h2>Collected Data</h2>
                        <p>No personal data is collected and no cookies are used.</p>
                        <p>Following information is tracked for
                            usage statistics: the website from which you visited us from, the parts of our site you
                            visit, the date and duration of your visit, your anonymised IP address, information from the
                            device (device type, operating system, screen resolution, language, country you are located
                            in, and web browser type) you used during your visit.
                        </p>
                        <p><Link className="underline"  target="_blank" to="https://matomo.org">Matomo</Link> is used to
                            collect this data. </p>
                        <h2>Opt-out</h2>
                        <p>Uncheck the following checkbox to opt-out of any tracking.</p>
                        <p><input className="mr-1" type='checkbox' checked={optedIn}
                                  onChange={toggle}/> {optedIn ? 'Opted-in' : 'Opted-out'}</p>

                    </div>
                </div>
                <div className="hidden md:inline-block flex-1 ml-16">
                    <StaticImage
                        src="../content/images/placeholder.png" alt=""
                        quality={90}/>
                </div>
            </div>
        </Layout>
    )
}


export default PrivacyPolicyEn;